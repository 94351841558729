import React from "react";
import { Box, Skeleton } from "@mui/material";

const CardsContentLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
      <Box sx={{ margin: 4 }}>
        <Skeleton variant="rectangular" width={410} height={68} />
        <Skeleton width="60%" />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    </div>
  );
};

export default CardsContentLoader;
